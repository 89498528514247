// Abstracts
@import './abstracts/abstracts';

// Base
@import './base/base';

// Components
@import './components/components';

// Layouts
@import './layout/layout';

// All pages
@import './pages/pages';

// Version Color
// NOTE: Dark Mode should be defined in layouts.
/* Base Styling */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
