@mixin flexJustifyCenter {
  display: flex;
  justify-content: center;
}

@mixin imageSizeSupportPage {
  object-fit: contain;
  aspect-ratio: 2/1;
  text-align: center;
}
/* 
 ! Support Screen Style
 */
.supportScreenBackground {
  @include defaultPageStyle;

  /* 
  ! Support Screen Back Button Style
 */
  .backButtonSupportPage {
    box-sizing: border-box;
    display: flex;
    width: 194px;
    height: 48px;
    left: 88px;
    top: 48px;
    border: 2px solid #fafafa;
  }

  /* 
 ! Support Page Landing Image
 */
  .supportPageLandingImage {
    padding: 52px 0px 0px 0px;
    width: 100%;
    text-align: center;
    justify-content: center;
    .flexJustifyCenterSupportPage {
      @include flexJustifyCenter;
      .supportPageLogoStyle {
        width: 194px;
        height: 41px;
      }
    }
  }
  /* 
 ! Support Page Content Style
 */
  .supportPageContentStyle {
    padding: 48px 0px 0px 0px;
    display: flex;
    justify-content: center;
    // align-items: center;

    .supportPageImgContent1 {
      @include imageSizeSupportPage;
    }
    .supportTitleStyle {
      padding: 16px 0px 0px 0px;
      text-align: center;
    }
    .supportButtonStyles {
      display: flex;
      justify-content: center;
      padding: 16px 0px 0px 0px;
    }

    .supportTimingMainRowStyle1 {
      padding: 8px 0px 24px 50px;
    }
    .supportTimingMainRowStyle2 {
      padding: 8px 0px 24px 00px;
    }

    .supportTimingDotColumn1 {
      display: flex;
      justify-content: end;
      height: 24px;
    }

    .supportTimingDotColumn2 {
      display: flex;
      justify-content: end;
      align-items: center;
    }

    .supportTimingDotTrue {
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: #37d184;
    }

    .supportTimingDotFalse {
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: #ea3d4a;
    }

    .supportTimingText {
      height: 24px;
    }

    .supportTechnicalDocImageStyle {
      @include imageSizeSupportPage;
    }
    .supportDiscordImageStyle {
      padding: 8px 0px 0px 0px;
      @include imageSizeSupportPage;
    }
  }
}
