.pagination-container {
  .heading-row {
    margin-top: 0.84rem ;
    padding: 0.4rem;
    display: flex;
  }
  .title-col {
    font-size: 1.66rem;
    font-weight: bold;
  }
  .header-row {
    padding: 1.1rem;
    background: #5E5E5E80;
    // margin-top: 1.3rem;
    border-radius: 0.75rem;
    color: #b0b0b0;
    font-size: 1.25rem;
    font-weight: bold;
  }
  .row-separator {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #474747;
    margin-left: 0rem;
    height: 3.75rem;
    font-size: 0.97rem;
    color: #f6f6f6;
  }
  .entry-margin {
    color: #CCCCCC;
    font-size: 1.12rem;
    font-weight: bold;
    padding-left: 0.66rem;
  }
  .pagination-col {
    nav {
      float: right;
    }
  }
  .searchbar-wrapper {
    * {
      float: right;
    }
  }

  @mixin pagination-item {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.25rem;
    height: 1.5rem;
    margin: 0.25rem;
    color: #b0b0b0;
  }
  .pagination-link {
    padding: 0.35rem 0.45rem !important;
  }
  .pagination-item-passive {
    button {
      @include pagination-item;
    }
  }

  .pagination-item-active {
    button {
      @include pagination-item;
      background: #b0b0b0;
      color: #4b4b4b;
    }
  }
}
