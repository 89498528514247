@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap");
html {
  font-size: 1vw;
  overflow-y: hidden;
}
html a {
  all: unset;
  text-decoration: none;
  cursor: pointer;
}
html a:hover {
  all: unset;
  text-decoration: none;
  cursor: pointer;
}

@font-face {
  font-family: "Articulat CF Medium";
  src:
    local("ArticulatCFArticulat CF Medium"),
    url("/public/fonts/ArticulatCF-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Fira-Code";
  src: local("FiraCodeBold") url("/public/fonts/FiraCode-Bold.ttf")
    format("truetype");
}

/* Styles for the scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #cccccc;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: #aaaaaa;
}
.react-flow__panel {
  display: none;
}
