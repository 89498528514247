// Base Styles
@use "../layout/variables" as *;

:root {
  --bg-background: #{$dark-bg-background};
}

.light-theme {
  --bg-background: #{$light-bg-background};
}
